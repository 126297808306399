<template>
  <div class="container">
    <h2 class="title">会员信息</h2>
    <div class="section">
      <h3 class="section-title">会员状态</h3>
      <div class="section-img">
        <div class="user-info">
          <img class="user-avatar"
            :src="formData.avatarUrl && $store.state.assetsUrl + formData.avatarUrl || DefaultAvatarUrl" alt="" />

          <span class="user-name">{{ formData.nickName }}</span>
          <span class="user-vip__icon"><img :src="formData.type == 1 ? VipBigIcon : VipIcon" alt="" /></span>
        </div>
        <div class="vip-status">
          <template v-if="formData.type == 1 && formData.expireDay">
            <span>剩余时间：{{ formData.expireDay }}</span>
            <el-button type="primary" size="mini" @click="isPurchaseModal = true">续费大会员</el-button>
          </template>
          <template v-else-if="formData.type == 1">
            <span>剩余时间：永久</span>
            <el-button type="primary" size="mini" disabled>成为大会员</el-button>
          </template>
          <template v-else>
            <span>剩余时间：已过期</span>
            <el-button type="primary" size="mini" @click="isPurchaseModal = true">成为大会员</el-button>
          </template>
        </div>
      </div>
    </div>

    <div class="section">
      <h3 class="section-title">会员记录</h3>
      <div class="section-item">
        <el-table :data="vipRecords" :header-cell-style="headerCellStyle" :cell-style="cellStyle" border max-height="500px">
          <el-table-column label="时间" prop="createTime" align="center" :formatter="timeFormatter"
            :width="200"></el-table-column>
          <el-table-column label="单号" prop="outTradeNo" align="center" :min-width="200"
            :formatter="emptyFormatter"></el-table-column>
          <el-table-column label="到期时间" prop="expirationTime" :formatter="timeFormatter" align="center"
            :width="200"></el-table-column>
          <el-table-column label="说明" prop="remark" align="center" :min-width="100"></el-table-column>
        </el-table>
      </div>
    </div>

    <h2 class="title">积分信息</h2>
    <div class="section">
      <h3 class="section-title">积分状态</h3>
      <div class="section-item">
        <p class="placeholder-text">攻城狮们正在努力了，敬请期待哦 (^.^)</p>
      </div>
    </div>

    <div class="section">
      <h3 class="section-title">积分记录</h3>
      <div class="section-item">
        <el-table :data="scoreRecords" :header-cell-style="headerCellStyle" :cell-style="cellStyle" border max-height="500px">
          <el-table-column label="时间" prop="createTime" align="center" :formatter="timeFormatter"
            :width="200"></el-table-column>
          <el-table-column label="积分" prop="outTradeNo" align="center" :min-width="200"
            :formatter="emptyFormatter"></el-table-column>
          <el-table-column label="说明" prop="remark" align="center" :min-width="100"></el-table-column>
        </el-table>
      </div>
    </div>

    <el-dialog class="vip-modal" :visible.sync="isPurchaseModal">
      <h2 class="vip-title">大会员套餐订阅</h2>
      <div class="group-list">
        <div class="group-list-item" :class="{ active: selectGroup == item.id }" @click="selectGroup = item.id"
          v-for="(item, index) in packages" :key="index">
          <span class="item-tag">{{ item.title }}</span>
          <i class="item-seperator"></i>
          <div class="item-info">
            <p class="item-info__name">
              <span class="item-info__price">{{ item.price }}</span>元/月
            </p>
            <p class="item-info__desc">{{ item.subTitle }}</p>
          </div>
        </div>
      </div>
      <div class="group-buy">
        <el-button type="primary" @click="submit">立即订阅</el-button>
      </div>
    </el-dialog>

    <el-dialog title="微信扫码支付" class="pay-modal" width="320px" :visible.sync="isPayModal" :close-on-click-modal="false"
      :close-on-close-on-press-escape="false">
      <div class="qrcode">
        <h1 class="payinfo-price">
          <span class="payinfo-price-symbol">¥</span>
          <span class="payinfo-price-amount">{{ payInfo.price }}</span>
          <span class="payinfo-price-unit">元</span>
        </h1>
        <h3 class="payinfo-title">{{ payInfo.title }}({{ payInfo.subTitle }})</h3>
        <p class="qrcode-tip">请在支付完成后，关闭当前窗口或刷新页面</p>
        <img :src="wechatPayQrCodeUrl" alt="微信支付二维码" />
      </div>
    </el-dialog>
  </div>
</template>
  
<script>
import { VipFormData } from "../../assets/data/formbuilder";
import { setOptions, parseTime } from "../../utils";
import DefaultAvatarUrl from "../../assets/imgs/default_avatar.png";
import VipIcon from "../../assets/imgs/vip_icon.png";
import VipBigIcon from "../../assets/imgs/vip_big_icon.png";
import QRCode from "qrcode";
export default {
  name: "Vip",
  data() {
    return {
      formData: new VipFormData().build(),
      DefaultAvatarUrl,
      VipIcon,
      VipBigIcon,
      // 会员信息
      vipRecords: [],
      // 积分信息
      scoreRecords: [],
      headerCellStyle: {
        background: "#1d1f22",
        border: "none",
        color: "#fff",
      },
      packages: [],
      cellStyle: {
        background: "#232529",
        color: "#fff",
        borderColor: "#1d1f22",
      },

      // 套餐弹窗
      isPurchaseModal: false,
      selectGroup: 1, // 选中的套餐
      wechatPayQrCodeUrl: null, // 支付二维码url
      isPayModal: false, // 支付弹窗
      payInfo: {}, // 支付的套餐信息

    };
  },
  computed: {
    avatarList() {
      return setOptions("avatar_list");
    },
    token() {
      return this.$store.state.token;
    },
    baseUrl() {
      return this.$store.state.baseUrl;
    },
    userInfo() {
      return this.$store.state.userInfo;
    },
  },
  created() {
    this.getSubscribePackages();
    this.getUserVipInfo();
  },
  methods: {
    getSubscribePackages() {
      this.$get("/vip/packages").then(res => {
        if (res.code != 200) return this.$message.error(res.message)
        this.packages = res.data || [];
      })
    },
    timeFormatter(row, cell, value, index) {
      return value ? parseTime(value) : "-";
    },
    emptyFormatter(row, cell, value, index) {
      return value || "-";
    },
    submit() {
      let packageInfo = this.packages.find(v => v.id == this.selectGroup);
      this.$post("/vip/takeOrder", {
        system: "web",
        price: packageInfo && packageInfo.price,
        packageId: this.selectGroup,
      }).then((res) => {
        console.log(res)
        if (res.code != 200) return this.$message.error(res.message);

        let loading = this.$loading({
          lock: true,
          text: "获取支付信息中，请稍后"
        })
        QRCode.toDataURL(res.data.codeUrl, { errorCorrectionLevel: 'H', margin: 2, width: 256 }, (err, url) => {
          loading.close();
          if (err) {
            console.log(err)
            this.$message.error("下单失败，请稍后再试");
            return;
          }
          this.isPurchaseModal = false;
          this.wechatPayQrCodeUrl = url;
          this.isPayModal = true;
          this.payInfo = packageInfo;
        })
      });
    },
    // 获取vip订单记录
    getUserVipInfo() {
      this.$get("/vip/vipInfo").then((res) => {
        if (res.code != 200) return this.$message.error(res.message);

        this.formData = new VipFormData(res.data.userInfo).build();
        this.vipRecords = res.data.vipRecords || [];
        this.$store.dispatch("updateUserBasic", {
          ...this.formData,
        });
      });
    },
  },
  watch: {
    isPayModal(newV) {
      if (!newV) this.getUserVipInfo(), this.payInfo = {};
    }
  }
};
</script>
  
<style lang="scss" scoped>
.container {
  min-width: 438px;
  max-width: 800px;
  margin: 0 auto;
  margin-top: 20px;
  border-radius: 20px;
  padding-bottom: 33px;
  box-sizing: border-box;

  .title {
    font-size: 12px;
    color: #fff;
    letter-spacing: 0;
    font-weight: 500;
    padding: 10px 20px;
    background-color: #232529;
    border-radius: 4px;
    margin-bottom: 30px;
  }

  .section {
    margin-bottom: 30px;
    border-radius: 4px;
    background-color: #232529;
  }

  .section-title {
    font-size: 12px;
    color: #fff;
    letter-spacing: 0;
    font-weight: 500;
    padding: 10px 20px;
    border-bottom: 1px solid #000;
  }

  .section-img {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    color: #fff;
    font-size: 12px;

    .user-info {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .user-avatar {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-right: 40px;
      }

      .user-name {
        font-size: 12px;
      }

      .user-vip__icon {
        width: 24px;
        height: 24px;
        margin-left: 10px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .vip-status {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      &>span {
        margin-right: 10px;
      }
    }
  }

  .section-item {
    padding: 20px 20px 0;
    color: #fff;
    font-size: 14px;

    .section-item-label {
      margin-right: 10px;
      display: inline-block;
      width: 80px;
    }

    .section-item-value {
      display: inline-block;
      width: calc(100% - 90px);
    }

    .nickname-input {
      width: 300px;
    }

    &:nth-last-of-type(1) {
      padding-bottom: 20px;
    }
  }

  .placeholder-text {
    text-align: center;
    color: #c8c7cc;
  }
}

.vip-modal {
  text-align: center;

  .vip-title {
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    color: #333;
    margin-bottom: 20px;
  }

  .group-list {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;

    .group-list-item {
      width: 250px;
      height: 122px;
      position: relative;
      border: 1px solid rgb(254, 169, 23);
      border-radius: 4px;
      margin: 10px;
      cursor: pointer;
      transition: 0.3s;

      .item-tag {
        position: absolute;
        right: -1px;
        top: -1px;
        background-color: rgb(254, 169, 23);
        color: #fff;
        width: 48px;
        height: 24px;
        line-height: 24px;
        border-radius: 0 4px 0 4px;
        font-size: 12px;
      }

      .item-info {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 100%;

        .item-info__name,
        .item-info__desc {
          color: rgb(254, 169, 23);
          line-height: 32px;
        }

        .item-info__name {
          font-size: 14px;

          .item-info__price {
            font-size: 24px;
          }
        }

        .item-info__desc {
          font-size: 16px;
        }
      }

      .item-seperator {
        display: block;
        position: absolute;
        width: calc(100% - 40px);
        height: 0;
        border-bottom: 1px dashed rgb(254, 169, 23);
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      &:hover,
      &.active {
        box-shadow: 1px 1px 4px rgb(254, 169, 23);
      }

      &.active::before {
        content: "";
        position: absolute;
        right: 0px;
        bottom: 0px;
        width: 16px;
        height: 16px;
        background: url("../../assets/imgs/check_icon.png") no-repeat;
        background-size: cover;
        background-position: center center;
        // transform: translate(-50%, -50%) rotate(-45deg);
        z-index: 2;
      }

      &.active::after {
        content: "";
        position: absolute;
        right: -1px;
        bottom: -1px;
        border-radius: 0 4px 4px 0;
        border: 15px solid transparent;
        border-right-color: rgb(254, 169, 23);
        border-bottom-color: rgb(254, 169, 23);
        z-index: 1;
      }
    }
  }
}

.qrcode {
  text-align: center;

  .qrcode-tip {
    color: #333;
    font-size: 14px;
  }

  .payinfo-price {
    line-height: 50px;
    height: 50px;
    color: orange;

    .payinfo-price-symbol {
      font-size: 24px;
    }

    .payinfo-price-amount {
      font-size: 28px;
      font-weight: bold;
      margin-right: 5px;
      margin-left: 2px;
    }

    .payinfo-price-unit {
      font-size: 16px;
      font-weight: normal;
    }
  }

  .payinfo-title {
    margin: 10px 0;
  }
}

.el-table--border::after,
.el-table--group::after,
.el-table::before {
  display: none;
}

.el-table--border,
.el-table--group {
  border-color: #1d1f22;
}

.el-table__body-wrapper {
  background-color: #232529;
}

/deep/.el-table__empty-block {
  background-color: #232529;
}

.pay-modal {
  /deep/.el-dialog__body {
    padding-top: 0;
  }
}
</style>
  